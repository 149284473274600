<template>
  <div>
  <div class="title pb-1">User Registrations Per Day of the Month [metric:count]</div>
    <b-card no-body>
    <b-card-header class="align-items-right">
      <!-- datepicker -->
      <div class="d-flex align-items-center"
      style="margin-right:0px;"
      >
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          @on-close="refetchData"
          :config="config"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="Select Date Range"
        />
      </div>
    </b-card-header>

    <!-- charts -->
    <b-card-body>
      <chartjs-line-chart
        v-if="loaded"
        :line-chart="usersBarChart" />
    </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import ChartjsBarChart from './ChartjsBarChart.vue'
import ChartjsLineChart from './ChartjsLineChart.vue'

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: $themeColors.primary, //'#FF913D',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  latestBarChartPrimary: '#666ee8',
  latestBarChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    flatPickr,
    vSelect,
    ChartjsBarChart,
    ChartjsLineChart,
  },
  data() {
    return {
      rangePicker: null,
      config: {
        mode: 'range',
      },
      loaded: false,
      usersBarChart: {
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: chartColors.successColorShade,
              borderColor: 'transparent',
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 1000,
                  min: 0,
                  max: 10000,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
    }
  },
  mounted() {
    // payments
    this.$http.get('/stats/series/users-per-month/')
      .then(response => {
        this.data = response.data
        console.log('Res', response.data)
        this.usersBarChart.data.labels = JSON.parse(JSON.stringify(response.data.graph_data.user_labels))
        this.usersBarChart.data.datasets[0].data = JSON.parse(JSON.stringify(response.data.graph_data.users_graph_data))
        this.loaded = true
      })
  },
  methods: {
    refetchData(dateStr) {
      this.loaded = false
      const start = moment(String(dateStr[0])).format('YYYY-MM-DD')
      const end = moment(String(dateStr[1])).format('YYYY-MM-DD')
      this.$http.get(`/stats/series/users-per-month/?created_at_before=${end}&created_at_after=${start}`).then(response => {
        this.usersBarChart.data.labels = JSON.parse(JSON.stringify(response.data.graph_data.user_labels))
        this.usersBarChart.data.datasets[0].data = JSON.parse(JSON.stringify(response.data.graph_data.users_graph_data))
        this.loaded = true
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-organisation-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
