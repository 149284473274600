<template>
    <!-- Stats Payments -->
    <div>
    <b-row>
      <b-col
        lg="6"
        sm="6"
      >
      <payin-per-month/>

      </b-col>
      <b-col
        lg="6"
        sm="6"
      >
      <user-per-month/>

      </b-col>

      </b-row>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BTab,
    BTabs,
  } from 'bootstrap-vue'
  
  import PayinPerMonth from './PayinPerMonth.vue'
  import UserPerMonth from './UserPerMonth.vue'
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardBody,
      BTab,
      BTabs,
      PayinPerMonth,
      UserPerMonth,
    },
}
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  